@import "../../_sass/main";


/* Tweaking for image backgrounds */

.bg-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-image-fill {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%; 
}

/* NAV CSS */

/*.dropdown-menu {
  z-index: 10001;
}*/



.dropdown {
   /*margin-top: 30%;*/
   transform-style: preserve-3d;
   transform: translate3d(0,0,10px) !important;
}

.dropdown-menu{
    height: auto !important;
    /*position: relative !important;*/
    transform: translate3d(0,0,10px) !important;
}

/*.navbar-btn
{
  padding: 6px 8px;
}

.menu_button.red
{
  background-color:#f00;
}

.menu_button.green, .menu_button.green:hover
{
  background-color:#43ac6a;
}

.navbar-btn.btn-success a
{
  color:#fff !important;
}

.nav .fa
{
  color:#aaa;
}
*/
/* MAIN STYLING CSS */

#site_content p, #site_content li, #site_content h4, #site_content h5
{
  line-height: 1.6em;
}

#site_content p
{
  margin: 0 0 15px;
}

#header_content
{
  margin-top:50px;
}

.jumbotron
{
  background: #e37117;
/*  opacity: 1;
  background-color: #fff;
  background-size: 40px 40px;
  padding:10px;*/
/*  background-image: linear-gradient(
                                    45deg, 
                                    rgba(255, 0, 0, 0.5) 12.5%, 
                                    transparent 12.5%, 
                                    transparent 25%, 
                                    rgba(62,109,179, 0.5) 25%, 
                                    rgba(62,109,179, 0.5) 37.5%, 
                                    transparent 37.5%,
                                    transparent 50%,
                                    rgba(255, 0, 0, 0.5) 50%,
                                    rgba(255, 0, 0, 0.5) 62.5%,
                                    transparent 62.5%,
                                    transparent 75%,
                                    rgba(62,109,179, 0.5) 75%, 
                                    rgba(62,109,179, 0.5) 87.5%,
                                    transparent 87.5%,
                                    transparent
                                    );*/
}

.jumbotron .fill
{
  /*margin:0;*/
  /*padding: 30px 0;*/
  /*background-color: #eee;*/
  
  /*background: #E8DEBC;*/
/*  width:100%;
  height:100%;*/
  color:#fff;
}

/*#jumbotron_content
{
  margin:auto 0;
}*/

.jumbotron h1
{
  font-size:2.2em;
  text-shadow:1px 1px 0px rgba(0,0,0,0.5);
}

.jumbotron li
{
  font-size: 1.2em
}

.jumbotron .button_container
{
  margin:20px 0 30px 0;
}

.top_spacer
{
  height:100px;
}

.promo-card {
  margin-bottom: 30px;
}

.card.highlight {
  box-shadow: 0px 0px 20px rgba(191,127,63,0.8) 
}

#sidebar_content
{
  /*padding: 30px;*/
  margin-bottom: 30px;
  color: inherit;
  /*background-color: #eee;*/
}

#footer_content
{
  border-top: 1px solid rgba(0,0,0,0.05);
  margin-top:50px;
  padding:20px 0;
  background-color: #e7e7e7;
}

footer p
{
  color:#999;
}

footer p a
{
  color:#555;
}

/* Video Embed */
.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }


/*.dropdown-menu>.active>a, .dropdown-menu>.active>a:hover, .dropdown-menu>.active>a:focus 
{
  background-color: rgba(66,139,202,0.1);
  color:#333;
}*/

/* CSS - Cascading Style Sheet */
/* Palette color codes */
/* Palette URL: http://paletton.com/#uid=10t0u0ksLsvhHzRmBu2ubmp-rhu */

/* Feel free to copy&paste color codes to your application */


/* As hex codes */

.color-primary-0 { color: #E37217 } /* Main Primary color */
.color-primary-1 { color: #FFB172 }
.color-primary-2 { color: #EF9146 }
.color-primary-3 { color: #B2550A }
.color-primary-4 { color: #8B3E00 }



/* As RGBa codes */

.rgba-primary-0 { color: rgba(227,114, 23,1) }  /* Main Primary color */
.rgba-primary-1 { color: rgba(255,177,114,1) }
.rgba-primary-2 { color: rgba(239,145, 70,1) }
.rgba-primary-3 { color: rgba(178, 85, 10,1) }
.rgba-primary-4 { color: rgba(139, 62,  0,1) }



/* Generated by Paletton.com Â© 2002-2014 */
/* http://paletton.com */

.btn-sample { 
  color: #ffffff; 
  background-color: #E37217; 
  border-color: #E37217; 
} 
 
.btn-sample:hover, 
.btn-sample:focus, 
.btn-sample:active, 
.btn-sample.active, 
.open .dropdown-toggle.btn-sample { 
  color: #ffffff; 
  background-color: #8B3E00; 
  border-color: #E37217; 
} 
 
.btn-sample:active, 
.btn-sample.active, 
.open .dropdown-toggle.btn-sample { 
  background-image: none; 
} 
 
.btn-sample.disabled, 
.btn-sample[disabled], 
fieldset[disabled] .btn-sample, 
.btn-sample.disabled:hover, 
.btn-sample[disabled]:hover, 
fieldset[disabled] .btn-sample:hover, 
.btn-sample.disabled:focus, 
.btn-sample[disabled]:focus, 
fieldset[disabled] .btn-sample:focus, 
.btn-sample.disabled:active, 
.btn-sample[disabled]:active, 
fieldset[disabled] .btn-sample:active, 
.btn-sample.disabled.active, 
.btn-sample[disabled].active, 
fieldset[disabled] .btn-sample.active { 
  background-color: #E37217; 
  border-color: #E37217; 
} 
 
.btn-sample .badge { 
  color: #E37217; 
  background-color: #ffffff; 
}
